import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, TitleStrategy, UrlSegment } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './_helpers';
import { TemplatePageTitleStrategy } from './title.strategy';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ProfileGuard } from './_helpers/profile.guard';
import { UserPublicProfileComponent } from './pages/pages/user-public-profile/user-public-profile.component';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages/front/front.module').then((m) => m.FrontModule)
      },
      {
        path: 'coming-soon',
        loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
        title: 'Coming Soon'
      },
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pages/pricing/pricing.module').then((m) => m.PricingModule),
        title: 'Pricing'
      }
    ]
  },
  {
    path: '',
    canActivate: [],
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/pages/auth/login/login.module').then((m) => m.LoginModule),
        title: 'Login'
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/pages/auth/register/register.module').then((m) => m.RegisterModule),
        title: 'Register'
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
        title: 'Forgot Password'
      },
      {
        path: 'i/:slug',
        loadChildren: () => import('./pages/pages/invite-page/invite-page.module').then((m) => m.InvitePageModule),
        title: 'Invite'
      },
      {
        path: 'b/:eventUuid',
        loadChildren: () => import('./pages/pages/meeting-scheduled-event/meeting-scheduled-event.module').then((m) => m.MeetingScheduledEventModule)
      },
      {
        matcher: (url) => {
          if (url[0].path.match(/^@[\w]+$/gm)) {
            return {
              consumed: url.slice(0, 1),
              posParams: {
                username: new UrlSegment(url[0].path.slice(1), {})
              }
            };
          }
          return null;
        },
        loadChildren: () => import('./pages/pages/user-public-profile/user-public-profile.module').then((m) => m.UserPublicProfileModule) // component: UserPublicProfileComponent
      }
    ]
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, ProfileGuard],
    children: [
      {
        path: 'apps',

        children: [
          /*{
            path: 'pages',
            loadChildren: () => import('./pages/apps/pages/page.module').then((m) => m.PageModule)
          },*/
          /*{
            path: 'users',
            loadChildren: () => import('./pages/apps/users/user.module').then((m) => m.UserModule)
          }*/
        ]
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages/member/member.module').then((m) => m.MemberModule)
      },

      {
        path: 'all-invites',
        loadChildren: () => import('./pages/pages/all-invites/all-invites.module').then((m) => m.AllInvitesModule)
      },
      {
        path: 'all-events',
        loadChildren: () => import('./pages/pages/all-events/all-events.module').then((m) => m.AllEventsModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/pages/account-settings/account-settings.module').then((m) => m.AccountSettingsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/pages/profile-page/profile-page.module').then((m) => m.ProfilePageModule)
      },
      {
        path: 'public-profile',
        loadChildren: () => import('./pages/pages/public-profile/public-profile.module').then((m) => m.PublicProfileModule)
      },
      {
        path: 'invite',
        loadChildren: () => import('./pages/pages/new-links/new-link.module').then((m) => m.NewLinkModule)
      },
      {
        path: 'availability',
        loadChildren: () => import('./pages/pages/availability-component/availability.module').then((m) => m.AvailabilityCompModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/pages/auth/change-password-user/change-password.module').then((m) => m.ChangePasswordUserModule)
      }
    ]
  },

  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'onboard',
        loadChildren: () => import('./pages/pages/onboard/onboard.module').then((m) => m.OnboardModule),
        title: 'Onboard'
      }
    ]
  },

  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule, QuicklinkModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    }
  ]
})
export class AppRoutingModule {}
