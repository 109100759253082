import { Calendar } from './calendar';

export class User {
  uuid: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  imageFile: {
    name: string;
    url: string;
    uuid: string;
    isPublic: boolean;
  };
  availabilityDefaultCalendar: Calendar;
  writeCalendar: Calendar;
}
