import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ResponseErrorModel } from '../models/responseErrorModel';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {
  private ignore: string[];

  constructor(private fb: UntypedFormBuilder, private toastr: ToastrService) {}

  createFormFromObject(item: any, options: FormOptionsFromObject = { ignoreNames: [], ignoreArray: false }) {
    const form: UntypedFormGroup | UntypedFormArray | UntypedFormControl = this.processObject(item, { ...options, isFirst: true });
    return form;
  }
  processObject(item: any, options: FormOptionsFromObject, name = ''): UntypedFormGroup | UntypedFormArray | UntypedFormControl {
    if (this.ignore?.includes(name)) {
      return item;
    }
    if (this.isObject(item)) {
      if (item.uuid) {
        //ignore first uuid, check if nested objects have uuid and if they have to create their own form control
        const objToCheckKey = { ...item };
        delete objToCheckKey.uuid;
        const isControl = options.controlNames?.includes(name);
        if ((!this.getObjectByKey(objToCheckKey, 'uuid') && !options?.isFirst) || isControl) {
          return this.fb.control(item);
        }
      }
      const object = Object.keys(item).reduce((prev, curr) => {
        prev[curr] = this.processObject(item[curr], { ...options, isFirst: false }, curr);
        return prev;
      }, {});
      return this.fb.group(object);
    } else if (this.isArray(item)) {
      if (options?.ignoreArray) {
        return this.fb.control(item);
      }
      return this.fb.array(
        item.map((itemElement) => {
          return this.processObject(itemElement, { ...options, isFirst: false });
        })
      );
    } else {
      return item;
    }
  }
  private isArray(item: any): boolean {
    return Array.isArray(item);
  }
  private isObject(item: any): boolean {
    return typeof item === 'object' && item !== null && !this.isArray(item);
  }

  // simulate radio button
  selectOnlyOne(previousItems: Array<any>, changedItems: any[], compareField: string, checkField: string): Array<any> {
    if (changedItems.length !== previousItems.length) {
      previousItems = changedItems;
      return changedItems;
    }
    let differentImage = null;
    changedItems.forEach((imageChanged) => {
      const previous = previousItems.find((previousImage) => previousImage[compareField] === imageChanged[compareField]);
      if (previous) {
        if (imageChanged[checkField] !== previous[checkField]) {
          differentImage = imageChanged;
        }
      }
    });
    changedItems.map((imageChanged) => {
      imageChanged[checkField] = imageChanged[compareField] === (differentImage ? differentImage[compareField] : null);
    });
    return changedItems;
  }

  // mappings needs to be {serverParam:'localParam'}
  setFormErrors(error: ResponseErrorModel, form: FormGroup<any> | FormArray<any>, mappings: any = null) {
    const apiErrors = error.errors;
    apiErrors.forEach((apiError) => {
      let formControl;
      if (mappings && mappings[apiError.property]) {
        formControl = form.controls[mappings[apiError.property]];
      } else {
        formControl = form.controls[apiError.property];
      }
      if (formControl) {
        formControl.setErrors({ apiError: Object.values(apiError.constraints)[0] });
      } else {
        // this is in case there is an error that doesn't match any of the fields
        this.toastr.error(Object.values(apiError.constraints)[0]);
      }
    });
  }

  _getObjectByKey(obj, key) {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return null;
    } else if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = this._getObjectByKey(obj[i], key);
        if (result) {
          return result;
        }
      }
    } else {
      for (const k in obj) {
        const result = this._getObjectByKey(obj[k], key);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  getObjectByKey(obj, key, fallback: any = null, getSecondKey = '') {
    let found;
    try {
      found = this._getObjectByKey(obj, key);
    } catch (err) {
      found = null;
    }
    if (found) {
      if (getSecondKey) {
        return found._get(getSecondKey, fallback);
      }
      return found;
    }
    return fallback;
  }
}

export interface FormOptionsFromObject {
  ignoreNames?: string[];
  controlNames?: string[]; // strings passed here will force controlNames instead of formGroup
  ignoreArray?: boolean;
  isFirst?: boolean;
}
