import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@hop/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@hop/services';
import { LayoutService } from '../@hop/services';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@hop/services';
import { HopConfigName } from '../@hop/config/config-name.model';
import { ColorSchemeName } from '../@hop/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@hop/components/config-panel/color-variables';
import { Select, Store } from '@ngxs/store';
import { AppHopState, LanguageSet, UserState } from '../@hop/state';
import { combineLatest, Observable } from 'rxjs';
import { User } from '../@hop/models';
import { NavigationItem } from 'src/@hop/interfaces/navigation-item.interface';
import { FeatureSetFromParams } from '../@hop/state';
import { AppState, AppStateModel } from './state/states/app-state.service';
import { debounceTime, filter } from 'rxjs/operators';
import { AppUpdateService } from './services';
import { LanguagesEnum } from '../@hop/models/language-model';
import * as cookie from 'js-cookie';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @Select(UserState.selectUser) user$: Observable<User>;
  @Select(AppState.selectColorScheme) app$: Observable<AppStateModel>;
  @Select(AppState.selectAppVersion) appVersion$: Observable<string>;
  language = navigator.language;
  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private store: Store,
    private router: Router,
    private appUpdateService: AppUpdateService,
    private translateService: TranslateService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val) => {
      // scroll to top
      setTimeout(() => {
        try {
          document.getElementsByTagName('mat-sidenav-content')[0].scrollTop = 0;
        } catch (e) {}
      }, 100);
    });
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver((name: string, namespace: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
      switch (namespace) {
        case 'mat':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/material-design-icons/two-tone/${name}.svg`);

        case 'logo':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/logos/${name}.svg`);

        case 'flag':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/flags/${name}.svg`);

        case 'social':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/social/${name}.svg`);
      }
    });

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as HopConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }

      if (queryParamMap.has('lang')) {
        const lang = LanguagesEnum[queryParamMap.get('lang')];
        //console.log(lang);
        if (lang) {
          this.store.dispatch(new LanguageSet({ name: lang, isManuallySet: true }));
        }
      }
    });
    // this.translateService.get(['home', 'pricing', 'dashboard', 'new-link', 'help']).subscribe((translations) => {
    //   console.log(translations);
    // });
    combineLatest([
      this.user$,
      this.translateService.stream(['home', 'pricing', 'dashboard', 'availability', 'new-link', 'help', 'login'])
    ]).subscribe((data) => {
      const user = data[0];
      const translations = data[1];
      //console.log(translations);
      /**
       * Add your own routes here
       */
      let items: NavigationItem[] = [
        {
          type: 'link',
          label: translations['home'],
          route: '/',
          icon: 'logo:logo',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: translations['pricing'],
          route: '/pricing',
          icon: 'mat:settings'
        },
        {
          type: 'link',
          label: translations['login'],
          route: '/login',
          icon: 'mat:settings'
        }

        /*{
          type: 'link',
          label: 'Sign Up',
          route: '/register',
          icon: 'mat:person'
          // class: 'button-inverted'
        }*/
      ];
      if (user) {
        items = [
          {
            type: 'link',
            label: translations['dashboard'],
            route: '/dashboard',
            icon: 'logo:logo'
          },
          {
            type: 'link',
            label: translations['availability'],
            route: '/availability',
            icon: 'mat:access_time'
          },
          {
            type: 'subheading',
            label: translations['new-link'],
            children: [
              { type: 'link', label: 'One to One', route: '/invite/one_on_one/create', icon: 'mat:person' },
              { type: 'link', label: 'One to many', route: '/invite/one_on_one/create', icon: 'mat:group_add' },
              { type: 'link', label: 'Group', route: '/invite/one_on_one/create', icon: 'mat:group' }
            ]
          },
          {
            type: 'link',
            label: translations['help'],
            route: '/help',
            badge: {
              value: '12',
              bgClass: 'bg-deep-purple',
              textClass: 'text-deep-purple-contrast'
            },
            icon: 'mat:person'
          }
        ];
      }
      this.navigationService.items$.next(items);
    });
  }
  @Select(AppHopState.selectLanguage) language$: Observable<any>;

  ngAfterViewInit(): void {
    this.router.events.pipe(debounceTime(300)).subscribe((val) => {
      // console.log(window.location.pathname);
      let pageClasses = [];
      let allBodyClasses = this.document.querySelector('body').classList;
      allBodyClasses.forEach((className) => {
        if (className.includes('page-')) {
          pageClasses.push(className);
        }
      });
      if (pageClasses) {
        pageClasses.forEach((className) => {
          this.renderer.removeClass(this.document.body, className);
        });
      }
      this.renderer.addClass(this.document.body, 'page-' + window.location.pathname.replace('/', ''));
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (
        Object.keys(params).reduce((prev, curr) => {
          if (!prev) {
            return !!/feature-/.test(curr);
          }
        }, false)
      ) {
        this.store.dispatch(new FeatureSetFromParams(params));
      }
    });
  }
}
